<template>
    <div>
        <div v-if="exist" class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-0">
            <div class="row justify-content-around bg-current px-2">
                <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-md d-block text-white"><b>Edit Achievement</b></h2></div>
            </div>
            <div class="card-body p-2" v-if="!isLoad">
                <form @submit.prevent="patchAssignments" class="row p-2">
                    <div class="col-12 form-group mb-3">
                        <label for="">Title</label>
                        <input type="text" v-model="assignment.title"  required placeholder="Enter your assignment title" class="form-control text-grey-900 font-xsss fw-600">                        
                    </div>
                    <div class="col-12 form-group mb-3">
                        <label for="">Description</label>
                        <textarea rows="1" v-model="assignment.description" required placeholder="Enter your assignment description" class="form-control text-grey-900 font-xsss fw-600"></textarea>
                    </div>
                    <div class="col-6 form-group mb-3">
                        <label for="">Cycle</label>
                        <select required v-model="cycle" @change="setUnit" class="form-control text-grey-900 font-xsss fw-600">
                            <option value="" disabled selected>-- Select Cycle --</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3 </option>
                        </select>
                    </div>
                    <div class="col-6 form-group mb-3">
                        <label for="">Unit</label>
                        <input v-if="unit == []" type="text" class="form-control" placeholder="-- Select Unit --" disabled>
                        <select v-else required v-model="assignment.unit_id" class="form-control text-grey-900 font-xsss fw-600">
                            <option value="" disabled selected>-- Select Unit --</option>
                            <option v-for="(item, index) in unit" :key="index" :value="item.id">
                                {{item.content}}
                            </option>
                        </select>
                    </div>
                    <div class="col-6 form-group mb-3">
                        <label for="">Due Date</label>
                        <input type="datetime-local" v-model="assignment.due_date" placeholder="Enter your assignment due date" class="form-control text-grey-900 font-xsss fw-600">                        
                    </div>
                    <div class="col-6 form-group mb-3">
                        <label for="">Time in Minutes</label>
                        <input type="number" required v-model="assignment.time" min="0" placeholder="Enter time minutes" class="form-control text-grey-900 font-xsss fw-600">
                    </div>
                    <div class="col-12 form-group mb-3">
                        <hr>
                    </div>
                    <div class="col-12 form-group text-right my-4">
                        <router-link :to="{name: 'AssignmentsCoursesAdmin', params: {idCourse: paramsId}, hash: '#achievement'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                        <input type="Submit" class="btn btn-current" value="Submit">
                    </div>
                </form>
            </div>
            <div class="card-body py-2" v-else>
                <div class="row">
                    <div class="col-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-0">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8 text-center default-page">
                    <div class="card border-0 text-center d-block">
                        <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                        <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                        <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                        <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    name: "AnouncementsCreate",
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            idAssignment: this.$route.params.idAssignment,
            isLoad: true,
            exist: true,
            syllabus: [],
            unit: [],
            cycle: '',
            assignment: {
                id: '',
                course_id: '',
                title: '',
                description: '',
                syllabus_id: '',
                unit_id: '',
                due_date: '',
                time: '',
            },
        }
    },
    created(){
        this.getAssignment()
    },
    methods:{
        async setUnit(){
            this.assignment.unit_id = ''
            if(this.cycle != '') {
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/syllabus/point?slug=${this.paramsId}&cycle=${this.cycle}` , {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.unit = res.data
                })
            }
        },
        async getAssignment(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/detail?slug=${this.paramsId}&id=${this.idAssignment}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                var data = res.data.data
                var list = []
                list.push(data.unit_id)
                this.unit = list
                this.assignment = {
                    id: data.id,
                    course_id: data.course_id,
                    title: data.title,
                    description: data.description,
                    syllabus_id: data.syllabus_id.id,
                    unit_id: data.unit_id.id,
                    due_date: data.due_date,
                    time: data.time,
                }
                this.cycle = data.unit_id.cycle
                this.isLoad = false
                
            }).catch(err => {
                if (err.response.status == 404) {
                    this.exist = false
                }
            })
        },
        async patchAssignments() {
            var data = {
                _method: 'patch',
                id: this.idAssignment,
                slug: this.paramsId,
                title: this.assignment.title,
                description: this.assignment.description,
                unit_id: this.assignment.unit_id,
                due_date: this.assignment.due_date,
                time: this.assignment.time,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Assignments - Achievement !',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'AssignmentsCoursesAdmin', hash: '#achievement' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Assignments - Achievement !',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },

    }
}
</script>